export enum VenetoColor {
  Black = "#4F4F4F",
  FullBlack = "#000000",
  DarkGray = "#686868",
  Gray = "#878787",
  MediumGray = "#BEBFBE",
  LightGray = "#E1E2E1",
  Background = "#EFF2F9",
  White = "#FFFFFF",
  DarkPurple = "#5260AD",
  Purple = "#7487F2",
  MediumPurple = "#A9B7FF",
  LightPurple = "#C0DEF5",
  DarkBlue = "#165AB5",
  Blue = "#008DC8",
  MediumBlue = "#6FB5EB",
  LightBlue = "#C0DEF5",
  DarkGreen = "#1A865F",
  Green = "#4CB383",
  MediumGreen = "#64D6A5",
  LightGreen = "#90F6C7",
  DarkSalmon = "#FE4E4E",
  Salmon = "#FB7878",
  MediumSalmon = "#FE9696",
  LightSalmon = "#FFB7B4",
  DarkOrange = "#C67C00",
  Orange = "#FFAB04",
  MediumOrange = "#FFDE04",
  LightOrange = "#FFDD4C",
  Transparent = "transparent",
  LightHeather = "#C3C4E2",
  Heather = "#8C8FBE",
  DarkHeather = "#6E7298"
}
